<template>
  <vx-card>
    <h1>إضافة مسابقة جديدة</h1>
    <div class="mb-5">&#8203;</div>
    <h4 class="mb-8">صورة المسابقة</h4>
    <div class="vx-row bottom-seperator">
      <div class="vx-col my-1 w-full mb-6">
        <div
          class="con-img-upload flex"
          id="images"
          v-if="contest.media.length"
        >
          <div
            class="img-upload"
            v-for="(image, index) in contest.media"
            :key="index"
          >
            <button type="button" class="btn-x-file" @click="spliceFile(index)">
              <i translate="translate" class="material-icons notranslate"
                >clear</i
              >
            </button>
            <img
              v-if="image.blob"
              :src="image.blob"
              style="max-width: none; max-height: 100%"
            />
          </div>
        </div>
        <file-upload
          ref="upload"
          :multiple="false"
          v-model="contest.media"
          class="vs-button bg-primary mt-5"
          post-action="/post.method"
          put-action="/put.method"
          @input-filter="inputFilter"
        >
          تنزيل صورة
        </file-upload>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col my-3 sm:w-1/2 w-full">
        <div class="vx-row">
          <div class="vx-col my-3 sm:w-full w-full mb-2">
            <vs-input
              class="w-full"
              name="name"
              data-vv-as="إسم المسابقة"
              v-validate="'required'"
              label-placeholder="إسم المسابقة"
              v-model="contest.name"
            />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{
              errors.first("name")
            }}</span>
          </div>
        </div>
        <div class="vx-row mt-8">
          <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
            <label for="contest">الشركة</label>
            <select
              v-model="contest.brand_id"
              class="w-full h-12"
              name="brand"
              data-vv-as="حقل الشركة"
              v-validate="'required'"
            >
              <option
                v-for="brand in brands"
                :key="brand.id"
                :value="brand.id"
              >
                {{ brand.name }}
              </option>
            </select>
            <span class="text-danger text-sm" v-show="errors.has('brand')">{{
              errors.first("brand")
            }}</span>
            <div class="description-text">لأي شركة تتبع هذه المسابقة</div>
          </div>
          <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
            <label for="contest">نوع المسابقة</label>
            <select
              v-model="contest.contestType_id"
              class="w-full h-12"
              name="contestType"
              data-vv-as="حقل نوع المسابقة"
              v-validate="'required'"
            >
              <option
                v-for="type in contestTypes"
                :key="type.value"
                :value="type.value"
              >
                {{ type.label }}
              </option>
            </select>
            <span
              class="text-danger text-sm"
              v-show="errors.has('contestType')"
              >{{ errors.first("contestType") }}</span
            >
          </div>
        </div>
        <div class="vx-row mt-8">
          <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
            <label for="contest">تاريخ بدأ المسابقة</label>
            <vs-input
              type="date"
              class="w-full"
              v-model="contest.startDate"
              name="startDate"
              data-vv-as="حقل تاريخ البداية"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('startDate')"
              >{{ errors.first("startDate") }}</span
            >
          </div>
          <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
            <label for="contest">تاريخ إنتهاء المسابقة</label>
            <vs-input
              type="date"
              class="w-full"
              v-model="contest.endDate"
              name="endDate"
              data-vv-as="حقل تاريخ النهاية"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('endDate')">{{
              errors.first("endDate")
            }}</span>
            <div class="description-text text-sm mt-2">
              عند الوصول لتاريخ الإنتهاء سيتم إلغاء تفعيل المسابقة
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col my-3 sm:w-1/2 w-full seperator">
        <div class="vx-row">
          <vs-checkbox
            class="mt-5 ml-5 pr-10 text-lg mb-10 mt-10 sm:w-1/2"
            v-model="contest.isMCQ"
          >
            إجابات متعددة
          </vs-checkbox>
          <vs-checkbox
            class="mt-5 ml-5 pr-10 text-lg mb-10 mt-10"
            v-model="contest.isActive"
          >
            هل المسابقة فعالة
          </vs-checkbox>
        </div>
        <div class="vx-row">
          <vx-input-group
            v-if="contest.isMCQ"
            class="vx-col my-3 w-full mt-12 mb-6"
          >
            <label for="contests">خيارات الإجابات للمسابقة</label>
            <div class="vx-row">
              <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                <vs-input
                  name="answer1"
                  data-vv-as="حقل الإجابة"
                  v-validate="'required'"
                  color="danger"
                  class="w-full"
                  label-placeholder="إجابة 1"
                  v-model="contest.answers[0].answer"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('answer1')"
                  >{{ errors.first("answer1") }}</span
                >
              </div>
              <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                <vs-input
                  color="danger"
                  class="w-full"
                  label-placeholder="إجابة 2"
                  v-model="contest.answers[1].answer"
                  name="answer2"
                  data-vv-as="حقل الإجابة"
                  v-validate="'required'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('answer2')"
                  >{{ errors.first("answer2") }}</span
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                <vs-input
                  color="danger"
                  class="w-full"
                  label-placeholder="إجابة 3"
                  v-model="contest.answers[2].answer"
                  name="answer3"
                  data-vv-as="حقل الإجابة"
                  v-validate="'required'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('answer3')"
                  >{{ errors.first("answer3") }}</span
                >
              </div>
              <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                <vs-input
                  color="success"
                  class="w-full"
                  label-placeholder="الإجابة الصحيحة"
                  v-model="contest.answers[3].answer"
                  name="answer4"
                  data-vv-as="حقل الإجابة"
                  v-validate="'required'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('answer4')"
                  >{{ errors.first("answer4") }}</span
                >
              </div>
            </div>
          </vx-input-group>
        </div>
      </div>
    </div>
    <div class="cr-contest-align mr-20">
      <vs-button
        class="mt-10 py-5 mb-5 cr-contest-btn"
        @click="submit"
        color="success"
        type="filled"
        >إنشاء المسابقة</vs-button
      >
    </div>
  </vx-card>
</template>

<script>
import moduleContest from "@/store/contests/moduleContest.js";
import moduleProduct from "@/store/products/moduleProduct.js";
import vSelect from "vue-select";
import VueUploadComponent from "vue-upload-component";

export default {
  components: {
    vSelect,
    "file-upload": VueUploadComponent,
  },
  data() {
    return {
      contestTypes: [
        {
          label: "يومية",
          value: "1",
        },

        {
          label: "شهرية",
          value: "2",
        },

        {
          label: "رمضانية",
          value: "3",
        },

        {
          label: "عيد الفطر",
          value: "4",
        },

        {
          label: "مخصصة",
          value: "5",
        },
      ],

      media_error: "",
      contest: {
        media: [],
        name: "",
        answers: [
          { answer: "", correct: false },

          { answer: "", correct: false },

          { answer: "", correct: false },

          { answer: "", correct: true },
        ],
        brand_id: "",
        contestType_id: "",
        startDate: "",
        endDate: "",
        isActive: true,
        isMCQ: true,
      },
    };
  },
  computed: {
    brands() {
      return this.$store.state.product.brands;
    },
  },
  methods: {
    changeStyle(e) {
      var btnStyle = e.target.style;
      btnStyle.color = "white";
      btnStyle.backgroundColor = "black";
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile.size / 1024.0 > 5000) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يجب أن لا يكون حجم الصورة أكبر من 5mb",
          color: "danger",
        });
        return prevent();
      }
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يجب أن يكون الملف بإمتداد صورة صحيح",
            color: "danger",
          });
          return prevent();
        }
      }
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    spliceFile(index) {
      this.contest.media.splice(index, 1);
    },
    submit() {
      // var answers = JSON.stringify(this.contest.answers);

      var answers = this.contest.answers;

      // REQUEST HERE
      this.$validator.validateAll().then((result) => {
        if (result) {
          var bodyFormData = new FormData();
          bodyFormData.set("name", this.contest.name);
          bodyFormData.set("mcq", this.contest.isMCQ ? 1 : 0);
          bodyFormData.set("answers", JSON.stringify(answers));
          bodyFormData.set("brand", this.contest.brand_id);
          bodyFormData.set("start_date", this.contest.startDate);
          bodyFormData.set("end_date", this.contest.endDate);
          bodyFormData.set("type", this.contest.contestType_id);
          bodyFormData.set("is_active", this.contest.isActive ? 1 : 0);

          // for (var i = 0; i < this.contest.media.length; i++) {
          //   var file = ;
          // }
          bodyFormData.append("question", this.contest.media[0].file);

          this.$store
            .dispatch("contest/createNewContest", bodyFormData)
            .then(() => {
              this.$vs.notify({
                title: "تم بنجاح",
                text: "تم اضافة المسابقة بنجاح",
                color: "success",
              });
              this.$router.push({ name: "contests" });
            })
            .catch(() => {
              this.$vs.notify({
                title: "حدث خطأ",
                text: "يرجى اعادة المحاولة",
                color: "danger",
              });
            });
        }
      });
    },
  },
  created() {
    if (!moduleContest.isRegistered) {
      this.$store.registerModule("contest", moduleContest);
      moduleContest.isRegistered = true;
    }
    if (!moduleProduct.isRegistered) {
      this.$store.registerModule("product", moduleProduct);
      moduleProduct.isRegistered = true;
    }

    this.$store.dispatch("product/fetchBrands");
  },
};
</script>

<style>
.cr-contest-btn {
  font-family: "Cairo", sans-serif;
}
.cr-contest-align {
  text-align: right;
}
.btn-upload-file {
  display: none;
}
.seperator {
  border-left: 1px solid rgb(204, 204, 204);
}
.bottom-seperator {
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 4em;
}
</style>
